@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");

html {
    scroll-behavior: smooth;
    background: #e8e8e8;
}

.page-wrapper {
    background: #eef5f9;
    border-bottom: 5px solid #2a4764;
}

@media screen and (prefers-reduced-motion: reduce) {
    html {
        scroll-behavior: auto;
    }
}

.topbar {
    background: #2a4764;
}

.topbar .top-navbar .navbar-header {
    width: 100%;
}

.topbar .navbar .navbar-header img {
    height: 58px;
}

.topbar .top-navbar .navbar-header .navbar-brand .dark-logo {
    display: none;
}

.topbar .top-navbar .navbar-header .navbar-brand .light-logo {
    display: inline-block;
    color: rgba(255, 255, 255, 0.8);
}

.topbar .navbar-light .navbar-nav .nav-item > a.nav-link {
    color: #ffffff !important;
}

.topbar .navbar-light .navbar-nav .nav-item > a.nav-link:hover, .topbar .navbar-light .navbar-nav .nav-item > a.nav-link:focus {
    color: rgba(255, 255, 255, 0.8) !important;
}

a.link:hover, a.link:focus {
    color: #2a4764 !important;
}

.topbar .top-navbar .waffle {
    width: 350px;
}

.topbar .top-navbar .waffle .waffle-item {
    padding: 15px 0 9px;
    border: 1px solid transparent;
}

.topbar .top-navbar .waffle .waffle-item:hover {
    border-color: #ebebeb;
}

.topbar .top-navbar .waffle .waffle-item img {
    height: 35px;
    width: auto;
}

.topbar .top-navbar .waffle .waffle-item span {
    margin-top: 5px;
    display: block;
    color: #000;
    font-size: 13px;
}

.huge-error-text {
    font-size: 120px;
    line-height: 200px
}

.bg-bader {
    background: #2a4764;
}

.pagination-btn {
    background: #FFF;
    color: #2a4764;
    font-weight: bold;
    border-color: #2a4764;
}

.pagination-btn-active {
    background: #2a4764 !important;
    color: #FFF !important;
}

.c-help:hover {
    cursor: help
}

.breadcrumb-item + .breadcrumb-item::before {
    content: ">" !important;
}

.list-group-item {
    padding: .4rem .8rem !important;
}

@media (min-width: 768px) {
    .navbar-header {
        width: 320px;
    }
}


/* Callout (taken from Bootstrap) */
.bd-callout {
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #eee;
    border-left-width: .25rem;
    border-radius: .25rem
}

.bd-callout h4 {
    margin-top: 0;
    margin-bottom: .25rem
}

.bd-callout p:last-child {
    margin-bottom: 0
}

.bd-callout code {
    border-radius: .25rem
}

.bd-callout + .bd-callout {
    margin-top: -.25rem
}

.bd-callout-info {
    border-left-color: #5bc0de
}

.bd-callout-info h4 {
    color: #5bc0de
}

.bd-callout-warning {
    border-left-color: #f0ad4e
}

.bd-callout-warning h4 {
    color: #f0ad4e
}

.bd-callout-danger {
    border-left-color: #d9534f
}

.bd-callout-danger h4 {
    color: #d9534f
}
